$fa-font-path: "../webfonts" !default;
@import '@fortawesome/fontawesome-pro/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-pro/scss/solid.scss';
@import '@fortawesome/fontawesome-pro/scss/regular.scss';
@import '@fortawesome/fontawesome-pro/scss/light.scss';


$primary: #15314D !default;
$success: #15C977 !default;
// $info: #7C69EF !default;
$warning: #ffc107 !default;
$danger: #EC3D47 !default;
$gray: #7B8793 !default;
$gray-light: #dfdfdf !default;
$gray-bg: #f8f8f8 !default;
$gray-dark: #050a10 !default;
$text-muted: $gray;
$black: #1A1A1A !default;

$body-color: $black;

$theme-colors: (
  "gray": $gray,
  "gray-dark": $gray-dark,
  "gray-light": $gray-light,
  "gray-bg": $gray-bg,
  "success": $success,
  "danger": $danger,
  "warning": $warning,
);

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .375,
  2: $spacer * .75,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

$h1-font-size: 2.75rem !default;
$h2-font-size: 2rem !default;
$h3-font-size: 1.5rem !default;
$h4-font-size: 1.25rem !default;
$h5-font-size: 1rem !default;
$h6-font-size: 0.875rem !default;
$font-size-sm: 0.9375rem !default;
$font-size-lg: 1.1875rem !default;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  sm: $font-size-sm,
  lg: $font-size-lg
) !default;

$border-radius: 2px;
$border-radius-sm: 2px;
$border-radius-lg: 3px;
$btn-white-space: nowrap;
$btn-font-weight: 600;

$btn-padding-y: .75rem;
$btn-padding-x: 1.5rem;

$btn-padding-y-sm: .5rem;
$btn-padding-x-sm: 1rem;

$btn-padding-y-lg: 1.125rem;
$btn-padding-x-lg: 1.75rem;


$modal-inner-padding: 1.25rem !default; // 20px
$modal-dialog-margin: 2.5rem .5rem;
$modal-dialog-margin-y-sm-up: 4.5rem;
$modal-sm: 409px;
$dropdown-min-width: 7rem;


// Colors defined by WeCreate:
// $blue: #4188EF; // used by $primary
// $gray-600: #70707D; // used by $secondary
// $gray: $gray-600;
// $gray-light: #B8B8BE;
// $orange: #F2A33A;
// $yellow: #FBC03B; // used by $warning
// $green: #4CC2A4;
// $red: #EC1C24;
// $red-light: #F69696;
// $blue-light: #8EADD3;
// $blue-dark: #336EC3;

$display-font-sizes: (
  1: 3rem,
  2: 2rem,
  3: 1.5rem,
  4: 1.25rem,
  5: 1rem,
  6: .875rem
) !default;

$input-btn-font-size: .875rem;
$input-btn-focus-width: 2px;
$input-border-color: rgba(0,0,0,.125);
$form-floating-label-opacity: .875;

$card-spacer-y: 1.25rem;
$card-spacer-x: 1.25rem;

$link-hover-color: var(--brand-hover);
$dropdown-link-color: $gray;
$dropdown-link-hover-color: var(--brand-hover);
$link-color: var(--brand-dark);
$dropdown-link-hover-bg: transparent;
$dropdown-link-active-bg: transparent;
$dropdown-link-active-color: var(--brand-hover);

$btn-link-color: var(--brand);
$btn-link-hover-color: var(--brand-hover);

$nav-pills-link-active-color: var(--brand);
$nav-pills-link-active-bg: transparent;
$nav-tabs-border-width: 2px;
$nav-tabs-border-color: transparent;
$nav-tabs-link-active-border-color: var(--brand);
$nav-pills-border-radius: 0;
$nav-link-color: $gray;

$navbar-brand-padding-y: 6px;
$navbar-nav-link-padding-x: 2rem;
$dropdown-padding-y: 1rem;

$accordion-icon-width: auto;

$breadcrumb-item-padding-x: 2rem;

$transition-collapse: height .15s ease !default;

$accordion-bg: #fffe; // a little transparency for when it overlays

$font-family-sans-serif: 'InterVariable', sans-serif !default;

@import 'bootstrap/scss/bootstrap.scss';

@import '@fontsource/inter/variable-full.css';

h1, h2, h3 {
  font-weight: 600;
}

h1, .ls-1 {
  letter-spacing: -0.0165rem;
}
h2, .ls-2 {
  letter-spacing: -0.008125rem;
}
h3, h4, .ls-3 {
  letter-spacing: -0.005rem;
}
h5, h6, .ls-4 {
  letter-spacing: -0.00375rem;
}

.fs-7 {
  font-size: .75rem;
}

header ~ main {
  padding-top: 56px;
}
main, footer {
  background-color: $gray-bg;
}

html {
  scroll-padding-top: 126px;
}

.justify-self-center {
  justify-self: center!important;
}

.dropdown.no-caret .dropdown-toggle::after {
  display: none;
}

.bg-primary, .accordion-button.bg-primary:not(.collapsed) {
  background-color: var(--brand);
  color: var(--brand-text);
}

.btn {
  line-height: 1.25;
  text-overflow: ellipsis;
}

.btn:not(.btn-default) {
  overflow: hidden;
}

.btn-primary {
  background-color: var(--brand);
  color: var(--brand-text);
  border-color: transparent;
}
.navbar-nav .nav-link.btn-primary.active, .navbar-nav .show>.nav-link.btn-primary {
  color: var(--brand-text);
}

.btn-primary:disabled, .btn-primary.disabled, fieldset:disabled .btn-primary {
  border-color: transparent;
}
.btn-primary:hover,
.btn-primary:focus {
  background-color: var(--brand-hover)!important;
  // filter: brightness(1.15);
  border-color: transparent!important;
  color: var(--brand-text)!important;
}

.btn-secondary {
  border-color: var(--brand);
  border-width: 2px;
  background-color: transparent;
  color: var(--brand);
}
.btn-secondary:hover,
.btn-secondary:focus {
  background-color: var(--brand-hover)!important;
  // filter: brightness(1.15);
  border-color: transparent!important;
  color: var(--brand-text)!important;
}

.btn-tertiary {
  background-color: transparent;
  border-color: transparent;
  color: var(--brand);
}

.btn-outline {
  color: var(--brand);
  border-color: var(--bs-gray-light);
  backdrop-filter: blur(4px);
}
.btn-outline:hover {
  color: var(--brand-hover)!important;
  background-color: var(--bs-gray-bg)!important;
}

.text-primary {
  color: var(--brand);
}
.btn.text-primary:hover, a.text-primary:hover {
  color: var(--brand-hover);
}

.btn:not(.btn-link):disabled {
  background-color: var(--bs-gray-light);
  color: #0006;
}

.border-primary {
  border-color: var(--brand)!important;
}

.form-floating {  // form-floating outlined style
  label {
    border: 0;
    color: var(--bs-gray);
  }

  .form-control, .form-select {
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;

    &:not(:focus) {
      border-radius: 0;
    }
  }
}

.form-floating > .form-control::placeholder {
  opacity: 0;
}

.form-floating > .form-control:focus::placeholder {
  opacity: 1;
  color: #C5CBD3
}

.form-floating > textarea.form-control {
  height: 90px;
}

.form-floating > .form-control[aria-expanded="false"] ~ label {
  opacity: inherit;
  transform: inherit;
}

.form-floating > .form-select:has(option:empty:checked) ~ label {
  font-size: inherit;
  opacity: inherit;
  transform: none;
}
.form-floating > .form-select[multiple] {
  height: auto;
}

.form-check {
  label {
    color: var(--bs-gray);
  }
}

.form-control.disabled {
  background-color: $input-disabled-bg;
}

// CSS-only modal, using hash
// .modal {
//   transition: opacity 200ms;
//   pointer-events: none;
//   display: block;
//   opacity: 0;
//   z-index: -1;
// }
// .modal:target {
//   pointer-events: all;
//   opacity: 1;
//   z-index: 1060;
// }
// .modal > .close {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background-color: #0002;
// }

.modal-content > button[data-bs-dismiss="modal"] {
  position: absolute;
  right: -2.5rem;
  top: -3rem;
}

.modal-dialog {
  max-width: min(var(--bs-modal-width), 100%);
}

label p, button p {
  display: contents; // deal with wurd markdown in buttons/labels, until wurd support inline markdown
  margin-bottom: 0;
}

@include media-breakpoint-up(lg) {
  .column-lg-2 {
    column-count: 2;
    column-gap: 1rem;
  }
}

@include media-breakpoint-up(xl) {
  .float-end-xl {
    float: right !important;
  }
}

input.form-control:read-only {
  background-color: $input-disabled-bg;
}

// fake :disabled style to still allow form validation
.form-control.disabled {
  background-color: $input-disabled-bg;
  opacity: 1;
}

.fw-500 {
  font-weight: 500!important;
}
.fw-bold, .fw-600 {
  font-weight: 600!important;
}
strong, th {
  font-weight: 600;
}

.breadcrumb-item + .breadcrumb-item::before {
  font-weight: 300;
}

[data-before]::before {
  content: attr(data-before);
  filter: opacity(.5);
  font-weight: 400;
  font-size: .8em;
  vertical-align: middle;
  margin-right: 0.1em;
}

.flex {
  display: flex!important;
  align-items: center!important;
}
.cover {
  object-fit: cover!important;
}
.contain {
  object-fit: contain!important;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}

ol, ul, dl, dd {
  margin-bottom: 0;
}
.mw-0 {
  min-width: 0;
}

// @include media-breakpoint-up(md) {
//   .navbar .dropdown-menu {
//     transform: translateX(-50%);
//   }
// }

.text-pre-wrap {
  white-space: pre-wrap!important;
}

@include media-breakpoint-up(sm) {
  .text-sm-center {
    text-align: center!important;
  }
}

summary {
  list-style: none;
}
.accordion-button[data-bs-toggle]::after, .summary::after {
  background: none;
  font-weight: 300;
  font-family: "Font Awesome 5 Pro";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 0;
  text-align: center;
  content: "\f078";
  transition: 150ms transform linear;
  margin-left: .25rem;
  align-self: center;
}

.accordion-button:not(.collapsed)::after {
  background: none;
}

details[open] > summary::after {
  transform: rotate(-.5turn);
}

details summary ~ * {
  animation: fade 400ms ease-in-out;
}
.summary::after {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(0turn);
}
.summary:not(.collapsed)::after {
  transform: translateY(-50%) rotate(-.5turn);
}

summary::before {
  font-weight: 400;
  font-family: "Font Awesome 5 Pro";
  content: "\f105";
  transition: 100ms transform ease-in-out;
  align-self: center;
  text-align: center;
  line-height: 0;
  margin-right: .25rem;
  display: inline-block;
  font-style: normal;
}
details[open] > summary::before {
  transform: rotate(.25turn);
}

@keyframes fade {
  0%    { opacity: 0; }
  100%  { opacity: 1; }
}

.navbar-dark .navbar-nav .nav-link, .dropdown-menu-dark .dropdown-item {
  color: var(--brand-text)!important;
}

@include media-breakpoint-down(md) {
  .navbar-collapse .nav-link {
    font-size: 1rem;
    font-weight: 500;
  }
  .navbar-collapse .navbar-nav {
    margin-top: .375rem;
    align-items: start;
  }
}
.navbar-nav {
  align-items: center;
}
.nav-link {
  font-weight: 600;
  font-size: .875rem;
}
.nav-link:not(.btn-primary) {
  color: rgba(var(--brand-dark-rgb),.75);
  &:hover {
  color: rgba(var(--brand-dark-rgb),.75);
    filter: brightness(2.25);
  }
  &.active {
    color: var(--brand);
    filter: brightness(.9375);
  }
}

.navbar .drowndown-header {
  padding: 0rem 1rem .5rem;
}

dropdown-menu-dark .dropdown-item:active, .dropdown-menu-dark .dropdown-item:active {
  background-color: unset;
}

@include media-breakpoint-down(md) {
  .navbar {
    .dropdown > a {
      display: none !important;
    }
    .dropdown-menu {
      border: 0;
      display: block;
      padding: 0;
    }
    .dropdown-divider {
      display: none;
    }
    .dropdown-header {
      display: none;
    }
    .dropdown-item {
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }
  }
}

input[type=file]::file-selector-button {
  display: none;
}

.form-control[required] + label::after,
.form-select[required] + label::after {
  content: '*';
  margin-left: .25rem;
  filter: opacity(.75);
}

.input-group {
  flex-wrap: nowrap;
}

.accordion {
  z-index: 3; // make sure it's above datepicker buttons
}
.accordion-collapse > * {
  box-shadow: none; // remove shadows of accordion content
}

:root {
  --border-light: 1px solid rgba(0,0,0,.125);
  --shadow-light: 0 0 1.25rem 0 rgba(0,0,0,0.06);
}

input {
  accent-color: var(--brand);
}

.alert p {
  margin-bottom: 0;
}

.alert em {
  font-style: normal;
  font-weight: 500;
}

.alert-info {
  background-color: var(--brand-light);
  color: var(--bs-gray-700);
}

.carousel-indicators > button:only-child {
  display: none;
}

.sgc-image {
  border-radius: 4px;
  max-height: 400;
  aspect-ratio: 7 / 4;
  width: 100%;
  object-fit: contain;
}

// gmap styling to prevent a bug on iphone where CSP nonce don't work
.gm-control-active>img{
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%,-50%);
  display:none;
}
.gm-control-active>img:nth-child(1){display:block;}

